import React, { useContext } from 'react';
import Card from '../common/Card';
import { XIcon } from '@heroicons/react/solid';
import EventSectionsForm, { EventSectionsCartModel } from './EventSectionsForm';
import { updateCartAndReloadUser } from '../members/api';
import { AuthContext } from '../../context/AuthContext';

export const EVENT_SECTION_CART_DEFAULT = {
  items: [],
  display: false,
  updatedAt: new Date().toISOString()
};

const EventSectionsCart = ({
  cart,
  goToCheckout
}: {
  cart: EventSectionsCartModel;
  goToCheckout: () => void;
}) => {
  const auth = useContext(AuthContext);
  return (
    <div>
      {cart?.display && cart?.items?.length ? (
        <div className="fixed top-10 right-10 max-w-xs bg-white z-10 ">
          <Card>
            <div className="flex justify-end">
              <XIcon
                onClick={() =>
                  updateCartAndReloadUser(
                    {
                      ...cart,
                      display: false
                    },
                    auth
                  )
                }
                className="cursor-pointer"
                width={20}
              />
            </div>
            <h3 className="flex font-bold justify-center text-lg mb-2">
              Your order
            </h3>

            <div className="overflow-y-auto з2" style={{ maxHeight: '75vh' }}>
              <EventSectionsForm
                hideSubmit={false}
                cart={cart}
                onSubmit={goToCheckout}
                submitText="Checkout"
              />
            </div>
          </Card>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default EventSectionsCart;
