import React from 'react';
import { Link } from 'gatsby';

interface NavItemProps {
  label: string;
  to?: string;
  onMouseEnter?: any;
  onMouseLeave?: any;
}

const NavItem = (props: NavItemProps) => {
  return (
    <Link
      to={props.to ? props.to : '/'}
      className="py-5 text-2xl font-semibold text-primary-dark hover:text-primary hover:underline sans-serif "
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
    >
      {props.label}
    </Link>
  );
};

export default NavItem;
