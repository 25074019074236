import React from 'react';
import SocialLinks from './SocialLinks';

const footerLinkGroups = [
  {
    title: 'Organization',
    links: [
      { title: 'Our Story', link: '/about' },
      { title: 'Our People', link: '/our-people' },
      { title: 'Contact', link: '/contact' },
      { title: 'Get Started', link: '/get-started' },
      { title: 'News', link: '/news' },
      { title: 'Blog', link: '/blog' }
    ]
  },
  {
    title: 'Services',
    links: [
      { title: 'Events', link: '/events' },
      { title: 'Partnering with TCRWP', link: '/services' }
    ]
  },
  {
    title: 'Resources',
    links: [
      { title: 'Videos', link: '/resources' },
      { title: 'Curricular Materials', link: '/resources' },
      {
        title: 'Assessments',
        link: '/resources'
      },
      {
        title: 'Results in Partner Schools',
        link: '/about'
      },
      {
        title: 'Job Board',
        link: '/job-board'
      }
    ]
  }
];

interface FooterLinkProps {
  title: string;
  link: string;
}

interface FooterLinkHeaderProps {
  title: string;
}

const FooterLinkHeader = (props: FooterLinkHeaderProps) => (
  <p className="pb-2 text-lg font-semibold text-primary sans-serif">
    {props.title}
  </p>
);

const FooterLink = (props: FooterLinkProps) => {
  return (
    <a
      href={props.link}
      className="px-4 py-2 ml-2 text-lg text-gray-500 sans-serif"
    >
      {props.title}
    </a>
  );
};

interface FooterLink {
  title: string;
  link: string;
}

interface FooterLinkGroup {
  title: string;
  links: FooterLink[];
}

interface FooterLinkGroupProps {
  group: FooterLinkGroup;
}

const FooterLinkGroup = (props: FooterLinkGroupProps) => (
  <div className="flex flex-col ">
    <FooterLinkHeader title={props.group.title} />
    {props.group.links.map((link, i) => (
      <FooterLink key={i} title={link.title} link={link.link} />
    ))}
  </div>
);

const Footer: React.FC = () => {
  return (
    <footer className="z-20 max-w-screen-lg p-4 m-auto bg-white sm:p-0">
      <div className="flex flex-col sm:flex-row">
        {/* <div className="flex flex-col justify-center w-screen sm:w-2/3 sm:flex-row">
          <div className="w-full mx-2">
            <FooterLinkGroup group={footerLinkGroups[0]} />
          </div>
          <div className="w-full mx-2">
            <FooterLinkGroup group={footerLinkGroups[1]} />
          </div>
          <div className="w-full mx-2">
            <FooterLinkGroup group={footerLinkGroups[2]} />
          </div>
        </div>
        <div className="justify-center w-full m-auto mt-4 sm:w-1/3 sm:m-auto">
          <SocialLinks />
        </div> */}
      </div>
      <div className="flex justify-center mx-auto mt-3 mr-5">
        <p className="text-gray-500">
          © {new Date().getFullYear()} Reading and Writing Project Network, LLC
        </p>
      </div>
    </footer>
  );
};

export default Footer;
